<!--
 * @Author: your name
 * @Date: 2021-05-08 14:49:04
 * @LastEditTime: 2021-05-08 15:18:56
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \one\src\components\station\index.vue
-->
<template>
  <div class="box">
    <div class="nomsgclass"></div>
    <p @click="cancel" class="msg">
      <van-icon name="arrow-left" size="0.34rem" style="margin-left: 0.2rem" />
      <span class="wan">选择职位</span>
    </p>
    <!-- <ul class="ul">
          <li v-for="(item, index) in list" :key="index" class="lis">
            {{ item.position_name }}
          </li>
        </ul> -->
    <div class="wrapper">
      <div class="left">
        <ul>
          <li
            v-for="(item, index) in list"
            :class="{ active: index === indexs }"
            :key="index"
            class="lis"
            @click="qh(index, item)"
          >
            {{ item.name }}
          </li>
        </ul>
      </div>
      <div class="right">
        <ul>
          <li
            v-for="(item, index) in lists"
            :key="index"
            @click="confirm(item.name, index, item)"
            :style="
              item.isse
                ? 'border: 1px solid #f55714;background: rgba(245, 87, 20, 0.16);'
                : ''
            "
          >
            {{ item.name }}
          </li>
        </ul>
      </div>
      <div class="xuanze">
        <div class="xz4">
          <span class="xz1">已选：</span>
          <ul class="xz3">
            <li
              style="display: flex"
              v-for="(item, index) in searray"
              v-bind:key="index"
            >
              <div v-for="(itemes, indexes) in item" v-bind:key="indexes">
                <li class="rrr" v-if="itemes">
                  {{ itemes.name }}
                  <van-icon
                    @click="dearraymeth(indexes, itemes)"
                    name="cross"
                  />
                </li>
              </div>
            </li>
          </ul>
        </div>
        <div @click="confirmmeth" class="xz2">确认</div>
      </div>
    </div>
  </div>
</template>

<script>
import eventbus from "../../util/evenBus";
export default {
  data() {
    return {
      activeKey: 0,
      list: [],
      lists: [],
      indexs: 0,
      aaa: "",
      searray: [],
      arr1: [],
      arr2: [],
    };
  },
  created() {
    this.aaa = eventbus;
    this.$http
       .post("/user/v1/Personal_info/industry", {
        reqType: "industry",
      })
      .then((res) => {
        let msg = JSON.parse(res.data).data;
        this.list = msg;
        this.lists = this.list[0].child;
      })
      .catch(() => {});
  },
  methods: {
    /***
     * 传入
     * 1:左侧菜单下标
     * 2:右侧菜单数据
     * 3:右侧菜单下标
     * 整理已选职位的数据
     * 二维数组
     */
    sortoutSelectArray(leftindex, rightmsg, rightindex) {
      this.arr2[rightindex] = rightmsg;
      this.arr2[rightindex].indexs = this.indexs;
      this.arr1[leftindex] = this.arr2;
      return this.arr1;
    },

    /**
     * 传入：
     * 1：左侧菜单下标
     * 2：右侧菜单下标
     * 清除已存在的数据（选中）
     * 已选：
     */
    clearMsgSelect(leftindex, rightindex) {
      this.$set(this.searray[leftindex], rightindex, "");
    },

    /**
     * 传入
     * 修改选中样式及数据
     */
    upStyleAndMsg(leftindex, rightmsg, rightindex) {
      let c = rightmsg;
      c.isse = false;
      this.$set(this.list[leftindex].child, rightindex, c);
    },

    /**
     * 传入：
     * 1:左侧菜单下标
     * 2:右侧菜单数据
     * 3:右侧菜单下标
     * 添加数据（选中）
     * 已选：
     */
    addMsgSelect(leftindex, rightmsg, rightindex) {
      return (this.searray[leftindex][rightindex] = rightmsg);
    },

    /**
     * 传入：
     * 1:数据
     * 将选择的数据转成字符串（选中）
     * 已选：
     */
    toStringSelect(searraymsg) {
      let a = [];
      searraymsg.forEach((element) => {
        if (element) {
          element.forEach((e) => {
            if (e) {
              a.push(e.name);
            }
          });
        }
      });
      return a;
    },

    confirmmeth() {
      this.$emit(
        "confirmstation",
        this.toStringSelect(this.searray).join("/")
      );
    },
    dearraymeth(index, item) {
      this.clearMsgSelect(item.indexs, index);
      this.upStyleAndMsg(item.indexs, item, index);
    },
    qh(index, item) {
      this.arr2 = [];
      this.indexs = index;
      this.lists = item.child;
    },
    confirm(name, index, item) {
      this.searray = this.sortoutSelectArray(this.indexs, item, index);
      if (item.isse) {
        this.clearMsgSelect(this.indexs, index);
        this.upStyleAndMsg(this.indexs, item, index);
        return;
      }
      if (this.toStringSelect(this.searray).length > 5) {
        this.$toast("最多选择五个");
        return;
      }
      this.addMsgSelect(this.indexs, item, index);
      let c = item;
      c.isse = true;
      this.$set(this.list[this.indexs].child, index, c);
    },
    cancel() {
      this.$router.go(-1);
      this.$emit("cancelstation");
    },
  },
};
</script>

<style scoped>
.xz4 {
  display: flex;
}
.xz3 {
  display: flex;
  overflow: auto;
  width: 8rem;
}

.rrr{
  margin-top: 0.45rem;
  white-space: nowrap;
  margin-left: 0.1rem;
  margin-right: 0.1rem;
  border-radius: 0.4rem;
  padding: 0.13rem 0.23rem;
  background: rgba(245, 87, 20, 0.16) !important;
  border: 1px solid #f55714 !important;
  font-size: 0.25rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #f55714;
}
.xz1 {
  min-width: 0.92rem;
  margin-left: 0.2rem;
  font-size: 0.25rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #697386;
  line-height: 1.54rem;
}
.xuanze {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 18%;
  background-color: white;
  border-top: 1px solid #ccc;
  z-index: 2000;
}
.xz2 {
  width: 6.86rem;
  height: 0.96rem;
  background: linear-gradient(90deg, #f55613 0%, #fc9e46 100%);
  border-radius: 0.48rem;
  font-size: 0.36rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #ffffff;
  text-align: center;
  line-height: 0.96rem;
  margin-left: 0.34rem;
  margin-bottom: 0.2rem;
}
.nomsgclass {
  height: 0.39rem;
}
.msg {
  height: 0.88rem;
  line-height: 0.88rem;
  line-height: 0.48rem;
  border-bottom: solid 0.01rem #eeeeee;
}

.msg span {
  font-size: 0.34rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #222222;
}
.msg .xia {
  float: right;
  margin-top: 0.24rem;
  margin-right: 0.3rem;
}

.msg .wan {
  margin-right: 0.3rem;
  margin-left: 2.6rem;
}
.ul {
  border-right: 1px solid #ccc;
  overflow: scroll;
  height: 100%;
}
.active {
  border-left: 2px solid red !important;
}
.lis {
  padding-left: 0.3rem;
  padding-top: 0.4rem;
  padding-bottom: 0.4rem;
  font-size: 0.3rem;
  height: 0.4rem;
  line-height: 0.4rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
/* 最外侧 */
.wrapper {
  width: 7.5rem;
  height: 100%;
  /* 弹性布局 */
  display: flex;
  /* 固定定位 */
  position: fixed;
}

/* 左侧列表 */
.left {
  width: 2.5rem;
  height: 73%;
  border-right: 1px solid #ccc;
  /* 添加滚轴 */
  overflow: scroll;
  position: absolute;
  top: 0;
  left: 0;
}
/* 右侧列表 */
.right {
  box-sizing: border-box;
  margin-left: 2.5rem;
  width: 5rem;
  height: 100%;
  overflow: scroll;
}
.right ul {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin: 0.1rem;
}
.right ul li {
  text-align: center;
  background-color: #f6f6f6;
  margin: 0.1rem;
  height: 0.6rem;
  line-height: 0.6rem;
  width: 44%;
  font-size: 0.3rem;
}
</style>

